import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Page.jsx";
import Divider from '../components/atoms/Divider';
import Important from '../components/atoms/Important';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Introduction`}</h2>
    <p>{`Thanks for using Checklist. This guide will help you get started by Q&A style. If you still need help you can write me directy on Discord channel or send email to `}<a parentName="p" {...{
        "href": "mailto:stevenjselcuk@gmail.com"
      }}>{`stevenjselcuk@gmail.com`}</a></p>
    <h2>{`How to clear demo checklist content?`}</h2>
    <p>{` `}<img parentName="p" {...{
        "src": "https://i.imgur.com/3Jbsp16.gif",
        "alt": null
      }}></img></p>
    <Divider mdxType="Divider" />
    <br />
    <h2>{`How to clear/set NoteBar note?`}</h2>
    <p>{` `}<img parentName="p" {...{
        "src": "https://i.imgur.com/ELDn8Tg.gif",
        "alt": null
      }}></img></p>
    <Divider mdxType="Divider" />
    <br />
    <h2>{`How to create a checklist?`}</h2>
    <p><img parentName="p" {...{
        "src": "https://imgur.com/sHRKqgm.gif",
        "alt": null
      }}></img></p>
    <Important text={"Don't forget to create backup and export your checklist database when you create a new checklist. Saving your exported checklist in other devices (like computer, Google Drive, iCloud) is highly recommended. "} mdxType="Important" />
    <br />
    <Divider mdxType="Divider" />
    <br />
    <h2>{`How to add a step to checklist?`}</h2>
    <p><img parentName="p" {...{
        "src": "https://imgur.com/HDBwFAn.gif",
        "alt": null
      }}></img></p>
    <br />
    <Divider mdxType="Divider" />
    <br />
    <h2>{`How to reorder a step in checklist or steps?`}</h2>
    <p><img parentName="p" {...{
        "src": "https://imgur.com/QXr8m8h.gif",
        "alt": null
      }}></img></p>
    <br />
    <Divider mdxType="Divider" />
    <br />
    <h2>{`How to delete a checklist?`}</h2>
    <p><img parentName="p" {...{
        "src": "https://imgur.com/OwlnQQt.gif",
        "alt": null
      }}></img></p>
    <br />
    <Divider mdxType="Divider" />
    <br />
    <h2>{`How to create backup/restore backup or export/import database (checklist file)?`}</h2>
    <p><img parentName="p" {...{
        "src": "https://imgur.com/oyY4XQn.png",
        "alt": null
      }}></img></p>
    <br />
    <Divider mdxType="Divider" />
    <br />
    <h2>{`How to share my checklist with other Checklist app users?`}</h2>
    <p>{`Tap to Export Database button and send your .checklist file to your friends via email or airdrop.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      